<template>
  <div>
    <div class="ps-2">
      <HeaderPage />
    </div>
    <SeeMorePage
      :title="'店舗からのお知らせ'"
      :data_list="shopNews"
      :data_type="'news'"
    />
    <Cashier />
    <Sidebar />
  </div>
</template>
<script>
import SeeMorePage from "../components/SeeMorePage.vue";
import HeaderPage from "../components/developmentv2/HeaderPage.vue";
import Sidebar from "../components/developmentv2/Sidebar.vue";
import Cashier from "../components/Cashier.vue";

export default {
  name: "ViewAllShopNews",
  components: {
    SeeMorePage,
    HeaderPage,
    Sidebar,
    Cashier,
  },
  data() {
    return {
      isLoadingFavStore: true,
    };
  },
  methods: {
    async shopNewsList() {
      this.isLoadingNews = true;
      let data = await this.$store.dispatch("shop_module/fetchShopNews");
      this.isLoadingNews = false;
      return data;
    },
  },
  computed: {
    shopNews() {
      return this.$store.state.shop_module.shopNews;
    },
  },
  created() {
    this.shopNewsList();
  },
};
</script>
